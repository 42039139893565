// roboto
// @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
:root {
  --orengeOne: #016af5;
  --black: #0a0908;
}

.all_main {
  height: 100vh;
  overflow: auto;
  max-width: 2000px;
  margin: 0 auto;
  // font-family: "Roboto", sans-serif;
  font-family: "Poppins", sans-serif !important;
  &::-webkit-scrollbar {
    width: 7px;
    background: transparent;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    border-radius: 0;
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #eaeaea78;
    border-radius: 10px;
  }
  .header_main {
    background: #0a0908;
    padding: 0 50px;

    @media screen and (max-width: 992px) {
      padding: 0 30px;
    }
    .header_wrap {
      border-bottom: 1px solid #eaeaea78;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 0;
      .logo_main {
        .ant-image {
          display: block;
        }
        .logo {
          height: 27px;
        }
      }
      .header_menu {
        display: flex;
        padding: 0;
        margin: 0;
        @media screen and (max-width: 768px) {
          display: none;
        }
        li {
          color: #fff;
          list-style-type: none;
          margin-right: 50px;
          font-size: 15px;
          cursor: pointer;
          @media screen and (max-width: 1040px) {
            margin-right: 20px;
          }
          font-weight: 500;
        }
      }
      .register_btn {
        display: block;
        background: transparent;
        border: none;

        a {
          background: var(--orengeOne);
          border: none;
          font-size: 15px;
          cursor: pointer;
          white-space: nowrap;
          height: 100%;
          border-radius: 50px;
          font-weight: 500;
          color: #fff;
          padding: 13px 30px;
          color: #fff;
          text-decoration: none;
        }
        @media screen and (max-width: 768px) {
          display: none;
        }
      }
      .menu_icon {
        height: 30px;
        display: none;
        cursor: pointer;
        @media screen and (max-width: 768px) {
          display: block;
        }
      }
    }
  }
  .dashboard_main {
    background: #0a0908;
    padding: 50px;
    padding-bottom: 0;
    position: relative;
    overflow: hidden;
    background-size: cover;
    background-position: 0 -20px;
    background-repeat: no-repeat;
    background-image: url("../assets/images/final.svg");
    @media screen and (max-width: 768px) {
      padding: 30px;
    }
    .dashboard_title {
      color: #fff;
      font-size: 40px;
      font-weight: 600;
      margin-top: 0;
      margin-bottom: 15px;
      text-align: center;
      @media screen and (max-width: 992px) {
        font-size: 32px;
      }
      @media screen and (max-width: 575px) {
        font-size: 26px;
      }
    }
    .dashboard_paragraph {
      width: 50%;
      margin: 0 auto;
      color: #ffffff80;
      font-size: 16px;
      margin-top: 0;
      text-align: center;
      @media screen and (max-width: 768px) {
        width: 90%;
        font-size: 13px;
      }
    }
    .bg_laptop_wrap {
      display: flex;
      justify-content: center;
      // overflow: hidden;
      // position: absolute;
      margin-bottom: -20px;
      .bg_laptop {
        border-radius: 10px;
        height: 500px;
        width: auto;
        // height: 100%;
        @media screen and (max-width: 992px) {
          height: auto;
          width: 90%;
        }
      }
      @media screen and (max-width: 992px) {
        margin-bottom: -60px;
      }
    }
    .dashboard_input {
      height: 50px;
      display: flex;
      padding: 4px;
      box-sizing: border-box;
      margin: 40px auto;
      width: 40%;
      background-color: #141414;
      border-radius: 50px;
      border: 1px solid #eaeaea78;
      @media screen and (max-width: 992px) {
        width: 70%;
      }
      @media screen and (max-width: 992px) {
        width: 90%;
      }
      .input {
        background: transparent;
        width: 100%;
        border: none;
        padding: 0 20px;
        color: #fff;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        &::placeholder {
          color: #eaeaea78;
        }
        &:focus {
          box-shadow: none;
        }
        &:focus-visible {
          outline: none;
        }
      }
      .get_btn {
        // background: transparent;
        background: var(--orengeOne);
        border: none;
        border-radius: 50px;
        a {
          cursor: pointer;
          height: 100%;
          white-space: nowrap;
          font-weight: 500;
          padding: 5px 30px;
          color: #fff;

          text-decoration: none;
          @media screen and (max-width: 768px) {
            padding: 5px 20px;
          }
        }
      }
    }
  }
  .network_main {
    background: var(--orengeOne);
    padding: 50px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    @media screen and (max-width: 768px) {
      padding: 30px;
    }
    .network_paragraph {
      width: 50%;
      @media screen and (max-width: 992px) {
        width: 100%;
      }
      h1 {
        font-size: 30px;
        color: #fff;
        margin: 0;
        font-weight: 600;
        @media screen and (max-width: 1240px) {
          font-size: 24px;
        }
        @media screen and (max-width: 992px) {
          text-align: center;
        }
        @media screen and (max-width: 575px) {
          font-size: 22px;
        }
      }
    }
    .client_main {
      width: 50%;
      display: flex;
      border-left: 1px solid #ffffff57;
      justify-content: end;
      align-items: center;
      @media screen and (max-width: 992px) {
        width: 100%;
        border: none;
        margin-top: 30px;
        justify-content: center;
      }
      .client_count {
        padding: 20px 30px;
        background: #2081f7;
        border-radius: 10px;
        // text-align: center;
        margin-left: 30px;
        @media screen and (max-width: 992px) {
          margin-left: 10px;
        }
        h1 {
          margin: 0;
          color: #fff;
          font-size: 36px;
          @media screen and (max-width: 575px) {
            font-size: 22px;
          }
        }
        p {
          margin-bottom: 0;
          font-size: 20px;
          font-weight: 600;
          text-transform: capitalize;
          color: #fff;
          @media screen and (max-width: 575px) {
            font-size: 18px;
          }
        }
      }
    }
  }
  .features_main {
    padding: 50px;
    background: #eaeaea;
    align-items: center;
    display: flex;
    width: 100%;
    @media screen and (max-width: 1240px) {
      flex-wrap: wrap;
    }
    @media screen and (max-width: 768px) {
      padding: 30px;
    }
    .features_image {
      width: 50%;
      @media screen and (max-width: 1240px) {
        width: 60%;
      }
      @media screen and (max-width: 992px) {
        width: 80%;
        margin: 0 auto;
      }
      @media screen and (max-width: 575px) {
        width: 95%;
        margin: 0 auto;
      }
      .features_image_wrap {
        margin-right: 40px;
        height: 400px;
        border-radius: 25px;
        background-color: var(--black);
        position: relative;
        overflow: hidden;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
          rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
        @media screen and (max-width: 992px) {
          margin: 0;
        }
        @media screen and (max-width: 575px) {
          height: 250px;
          margin: 0 auto;
        }
        img {
          position: absolute;
          height: 330px;
          rotate: 12deg;
          border-radius: 13px;
          left: -50px;
          top: 110px;
        }
      }
    }
    .features_content_main_wrap {
      width: 50%;
      @media screen and (max-width: 1240px) {
        width: 40%;
      }
      @media screen and (max-width: 992px) {
        width: 80%;
        margin: 0 auto;
      }
      @media screen and (max-width: 575px) {
        width: 100%;
      }
      .features_content_main {
        padding: 0 40px;
        @media screen and (max-width: 992px) {
          padding: 20px;
        }
        @media screen and (max-width: 575px) {
          padding: 0;
          padding-top: 20px;
        }
        .features_title {
          font-size: 18px;
          font-weight: 600;
          color: var(--orengeOne);
          @media screen and (max-width: 992px) {
            text-align: center;
          }
        }
        .features_subtitle {
          font-weight: 700;
          font-size: 41px;
          margin: 10px 0;
          width: 80%;
          @media screen and (max-width: 1240px) {
            width: 100%;
            font-size: 32px;
          }
          @media screen and (max-width: 992px) {
            text-align: center;
            font-size: 25px;
          }
          span {
            color: var(--orengeOne);
          }
        }
        .features_paragraph {
          color: #8d8d91;
          font-size: 14px;
          width: 80%;
          font-weight: 500;
          @media screen and (max-width: 992px) {
            width: 100%;
            text-align: center;
          }
        }
        .features_wrap {
          display: flex;
          margin: 20px 0;
          @media screen and (max-width: 992px) {
            justify-content: center;
          }
          p {
            font-size: 16px;
            font-weight: 500;
            margin-left: 10px;
          }
        }
      }
    }
  }
  .above_main {
    padding: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 85%;
    margin: 0 auto;
    @media screen and (max-width: 1240px) {
      flex-wrap: wrap;
    }
    @media screen and (max-width: 992px) {
      width: 100%;
    }
    @media screen and (max-width: 768px) {
      padding: 30px;
    }
    .above_wrap {
      width: 50%;
      @media screen and (max-width: 1240px) {
        width: 100%;
      }
      .above_title {
        font-weight: 700;
        font-size: 41px;
        margin: 10px 0;
        line-height: 45px;
        width: 80%;
        @media screen and (max-width: 1240px) {
          text-align: center;
          width: 100%;
          line-height: normal;
          font-size: 32px;
        }
        @media screen and (max-width: 992px) {
          font-size: 25px;
        }
        span {
          color: var(--orengeOne);
          .pos {
            font-weight: 500;
            font-size: 33px;
            @media screen and (max-width: 1240px) {
              font-size: 27px;
            }
            @media screen and (max-width: 992px) {
              font-size: 23px;
            }
          }
        }
      }
      .above_paragraph {
        color: #8d8d91;
        font-size: 15px;
        width: 9 0%;
        font-weight: 500;
        margin-top: 10px;
        @media screen and (max-width: 1240px) {
          text-align: center;
          width: 100%;
        }
      }
      .above_details_main {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        @media screen and (max-width: 425px) {
          justify-content: center;
        }
        .above_details_wrap {
          width: 39%;
          text-align: center;
          padding: 10px;
          margin-top: 20px;
          border-radius: 10px;
          box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
          @media screen and (max-width: 1240px) {
            width: 30%;
          }
          @media screen and (max-width: 768px) {
            width: 40%;
          }
          @media screen and (max-width: 425px) {
            width: 70%;
          }
          img {
            height: 65px;
            width: 65px;
          }
          p {
            font-size: 17px;
            font-weight: 500;
          }
        }
      }
    }
    .user_main {
      width: 40%;
      @media screen and (max-width: 1240px) {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      @media screen and (max-width: 768px) {
        flex-wrap: wrap;
      }
      .user_wrap {
        padding: 20px;
        border-radius: 15px;
        background: #016af530;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
        @media screen and (max-width: 1240px) {
          width: 45%;
        }
        @media screen and (max-width: 768px) {
          width: 100%;
          margin-top: 4%;
        }
        p {
          font-size: 14px;
          color: #016af5;
          font-weight: 500;
        }
        .user_prfile_main {
          display: flex;
          align-items: center;
          margin-top: 16px;
          .user_name_main {
            margin-left: 10px;
            .company_name {
              font-size: 16px;
              font-weight: 600;
              color: #016af5;
            }
            .user_name {
              font-size: 14px;
              font-weight: 500;
              color: #016af5;
            }
          }
        }
      }
      .user_wrap:nth-child(2) {
        margin-top: 40px;
        background-color: #ffd10030;
        p {
          color: #c29f00;
        }
        .user_prfile_main {
          .user_name_main {
            .company_name,
            .user_name {
              color: #c29f00;
            }
          }
        }
      }
    }
  }
  .pricing_main {
    padding: 50px;
    background: #eaeaea;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    @media screen and (max-width: 768px) {
      padding: 30px;
    }
    .pricing_title {
      font-size: 18px;
      font-weight: 600;
      color: var(--orengeOne);
    }
    .pricing_sub_title {
      color: black;
      width: 50%;
      text-align: center;
      font-weight: 700;
      font-size: 41px;
      line-height: 50px;
      margin: 10px 0;
      @media screen and (max-width: 1240px) {
        font-size: 32px;
        width: 70%;
        line-height: normal;
      }
      @media screen and (max-width: 992px) {
        font-size: 25px;
        width: 100%;
      }
      span {
        color: var(--orengeOne);
      }
    }
    .pricing_paragraph {
      color: #8d8d91;
      font-size: 15px;
      width: 60%;
      text-align: center;
      font-weight: 500;
      margin-top: 10px;
      @media screen and (max-width: 1240px) {
        width: 80%;
      }
      @media screen and (max-width: 992px) {
        width: 100%;
      }
    }
    .pricing_card_main {
      display: flex;
      width: 85%;
      margin: 0 auto;
      justify-content: space-between;
      margin-top: 40px;
      @media screen and (max-width: 1240px) {
        width: 100%;
      }
      @media screen and (max-width: 992px) {
        flex-wrap: wrap;
        justify-content: center;
        gap: 30px;
      }
      .pricing_card_wrap {
        padding: 30px;
        box-shadow: rgba(0, 0, 0, 0.1) 3px 32px 33px;
        background: #fff;
        width: 30%;
        box-sizing: border-box;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @media screen and (max-width: 992px) {
          width: 45%;
        }
        @media screen and (max-width: 575px) {
          width: 80%;
        }
        @media screen and (max-width: 425px) {
          width: 100%;
        }
        .pricing_image {
          border: 1px solid #b8b8b8;
          border-radius: 50%;
          width: 45px;
          height: 45px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .plan_title {
          font-size: 20px;
          font-weight: 600;
          margin: 20px 0 7px 0;
          text-transform: capitalize;
        }
        .plan_price {
          font-size: 14px;
          font-weight: 500;
          span {
            font-size: 36px;
            font-weight: 600;
          }
        }
        .plan_hr {
          border: 1px solid black;
          border-top: none;
          border-right: none;
          border-left: none;
          margin: 10px 0 20px 0;
        }
        .plan_details {
          // margin-top: 20px;
          // height: 100%;
          .plan_details_wrap {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            p {
              font-size: 15px;
              font-weight: 400;
              margin-left: 11px;
            }
          }
        }
        .plan_btn {
          border: none;
          width: 100%;
          background: #eaeaea;
          padding: 15px;
          font-size: 15px;
          font-weight: 600;
          margin-top: 20px;
          border-radius: 10px;
          cursor: pointer;
        }
      }
      .pricing_card_wrap:nth-child(2) {
        border: 1px solid #016af5;
        .plan_price {
          .deleted_pricing {
            font-size: 14px;
            font-weight: 500;
            margin: 0 6px;
            text-decoration: line-through;
          }
        }
        .plan_btn {
          background: #016af5;
          color: #fff;
        }
      }
    }
  }
  .sales_main {
    background: #0a0908;
    padding: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @media screen and (max-width: 768px) {
      padding: 30px;
    }
    .sales_title {
      width: 50%;
      text-align: center;
      font-weight: 600;
      font-size: 41px;
      line-height: 50px;
      margin: 10px 0;
      color: #fff;
      @media screen and (max-width: 1240px) {
        font-size: 32px;
        width: 70%;
        line-height: normal;
      }
      @media screen and (max-width: 992px) {
        font-size: 25px;
        width: 100%;
      }
    }
    .sales_paragraph {
      color: #8d8d91;
      font-size: 14px;
      width: 80%;
      text-align: center;
      font-weight: 500;
      margin-top: 10px;
      @media screen and (max-width: 1240px) {
        width: 90%;
      }
      @media screen and (max-width: 992px) {
        width: 100%;
      }
    }
    .sales_button {
      background: var(--orengeOne);
      border: none;
      padding: 13px 30px;
      margin-top: 30px;
      border-radius: 50px;
      a {
        font-weight: 500;
        height: 100%;
        cursor: pointer;
        color: #fff;
        text-decoration: none;
        font-size: 17px;
        // font-weight: 600;
      }
    }
  }
  .footer_main {
    padding: 50px;
    @media screen and (max-width: 768px) {
      padding: 30px;
    }
    .footer_wrap {
      padding-bottom: 30px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #8d8d91;
      @media screen and (max-width: 1040px) {
        gap: 20px;
        flex-wrap: wrap;
      }
      @media screen and (max-width: 575px) {
        padding-bottom: 10px;
      }
      .ready_section_main {
        width: 45%;
        @media screen and (max-width: 1040px) {
          width: 80%;
        }
        @media screen and (max-width: 575px) {
          width: 100%;
        }
        .footer_logo_main {
          display: flex;
          align-items: center;
          @media screen and (max-width: 575px) {
            flex-wrap: wrap;
          }
          .footer_logo {
            margin-right: 20px;
            height: 30px;
          }
          .footer_title {
            font-size: 1.25rem;
            font-weight: 600;
            margin-top: 6px;
            @media screen and (max-width: 575px) {
              margin-top: 10px;
            }
          }
        }
        .footer_paragraph {
          color: #8d8d91;
          font-size: 14px;
          text-align: left;
          font-weight: 500;
          margin: 30px 0;
        }
        .get_start_btn {
          // background: var(--orengeOne);
          // border: none;
          // cursor: pointer;
          // white-space: nowrap;
          // height: 100%;
          // font-size: 16px;
          // border-radius: 50px;
          // font-weight: 600;
          // color: #fff;
          // padding: 13px 30px;

          background: var(--orengeOne);
          border: none;
          padding: 13px 30px;
          // margin-top: 30px;
          border-radius: 50px;
          a {
            font-weight: 500;
            height: 100%;
            cursor: pointer;
            color: #fff;
            text-decoration: none;
            font-size: 17px;
            // font-weight: 600;
          }
          // margin-top: 20px;
        }
      }
      .page_link_main {
        width: 10%;
        @media screen and (max-width: 575px) {
          width: 100%;
        }
        .page_link_list {
          font-weight: 500;
          margin: 5px 0;
          text-align: center;
        }
      }
      .searching_main {
        width: 45%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @media screen and (max-width: 1040px) {
          width: 100%;
        }
        .searching_wrap {
          display: flex;
          @media screen and (max-width: 575px) {
            flex-wrap: wrap;
          }
          .searching_title {
            font-size: 1.25rem;
            font-weight: 600;
            margin-top: 6px;
          }
          .searching_paragraph {
            color: #8d8d91;
            font-size: 14px;
            width: 80%;
            text-align: left;
            font-weight: 500;
            margin-top: 10px;
          }
          .searching_btn {
            background: var(--orengeOne);
            border: none;
            cursor: pointer;
            font-size: 16px;
            border-radius: 50px;
            margin-top: 20px;
            height: 49px;
            // height: 100%;
            a{
              white-space: nowrap;
              font-weight: 500;
              color: #fff;
              text-decoration: none;
              padding: 13px 30px;

            }
          }
        }
        .privacy_link_main {
          color: white;
          display: flex;
          justify-content: space-between;
          margin: 20px 0 0 0;
          @media screen and (max-width: 575px) {
            flex-wrap: wrap;
          }
          h4 {
            font-size: 1.125rem;
            font-weight: 500;
            color: #fff;
            margin: 0;
            cursor: pointer;
            a {
              color: #000;
              display: inline-block;
              padding-bottom: 0.313rem;
              position: relative;
              text-decoration: none;
              font-size: 1rem;
              font-weight: 500;
            }
          }
          p {
            font-size: 1.125rem;
            font-weight: 500;
            color: #9e9e9e;
            margin: 0;
            cursor: pointer;
          }
        }
      }
    }
    .footer_copy {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 20px 0 0 0;
      img {
        width: 15px;
        height: 15px;
      }
      p {
        color: #8d8d91;
        font-size: 15px;
      }
    }
  }
}
/* color: #ff7c56 orenge
color: #0a0908 black
color: #eaeaea gray */
.ant-image-mask {
  display: none !important;
}
